<template>

    <div>

        <!--Header-->
        <div slot="header" style="margin-bottom: 15px;">

            <span class="d-flex w-100 justify-content-between flex-wrap">

                <!--Left side-->
                <span>
                    <!--Title-->
                    <h5 style="margin-bottom: 0"><b>Edit Floor Plans</b></h5>

                    <!--Text Box-->
                    <b-form-input v-if="uploadedBool === true" v-model="floorPlanName" placeholder="Floor Plan Name" style="margin-top: 10px;"/>
                </span>

                <!--Right side-->
                <span style="display: inline-block; white-space: nowrap">
                    Constellation:
                    <b-form-select v-model="currentConstellation" :options="constellations" style="width: fit-content"
                                   @input="getConstellation()">
                    </b-form-select>
                </span>

            </span>

        </div>

        <!--Picture Window-->
        <div id="picture-window"
             style="display: flex; justify-content: center; flex-direction: column; align-items: center;">

            <!--Image Parent-->
            <div id="panzoom-element">
                <img id="img" :src="image" v-if="uploadedBool === true"  @click="placeIcon($event.offsetX, $event.offsetY)">
            </div>

        </div>

        <!--Buttons-->
        <div v-if="uploadedBool === true" style="margin-top: 25px; display: flex; justify-content: center; align-items: center;">

            <b-button v-if="uploadedBool === true" @click="submitFloorPlan()" style="margin-right: 10px;">
                Save Floor Plan
            </b-button>

            <b-button v-if="devicesPlaced.length > 0" @click="removeDevices()">
                Remove Icons
            </b-button>
        </div>

        <!--Devices Tables-->
        <div v-if="currentConstellation !== '' && uploadedBool === true" style="margin-top: 25px; display: flex; flex-direction: row;">

            <!--Devices Not Placed Table-->
            <b-card style="width: 100%; height: 100%">
                <div slot="header" no-body>
                    <span class="d-flex w-100 justify-content-between">
                        <h5 style="margin-bottom: 0"><b>Devices Not Placed: {{ currentConstellation.name }}</b></h5>
                    </span>
                </div>
                <v-client-table @row-click="changeCurrentDevice" ref="dTable" style="width: 100%" :columns="columnsNotPlaced" :data="devicesNotPlaced" :options="tOptions2" :theme="theme" class="dataTable">

                </v-client-table>
            </b-card>

            <!--Devices Placed Table-->
            <b-card style="width: 100%; height: 100%">
                <div slot="header" no-body>
                    <span class="d-flex w-100 justify-content-between">
                        <h5 style="margin-bottom: 0"><b>Devices Placed: {{ currentConstellation.name }}</b></h5>
                    </span>
                </div>
                <v-client-table ref="dTable" style="width: 100%" :columns="columnsPlaced" :data="devicesPlaced" :options="tOptions2" :theme="theme" class="dataTable">
                    <div slot="controls" slot-scope="props" style="float: left">
                        <fa-icon :icon="['fas', 'rotate-left']"
                           @click="rotateDevice(props.row.tempId, -15)"/>&nbsp;
                        <fa-icon :icon="['fas', 'rotate-right']"
                           @click="rotateDevice(props.row.tempId, 15)"/>&nbsp;
                        <fa-icon :icon="['fas', 'pen-to-square']"
                           @click="editDevicePlacement(props.row)"/>&nbsp;
                        <fa-icon :icon="['fas', 'close']"
                           @click="removeDevice(props.row)"/>
                    </div>
                </v-client-table>
            </b-card>
        </div>

        <!--Select Floor Plans Table-->
        <div style="margin-top: 15px;">

            <v-client-table @row-click="loadFloorPlan" ref="dTable" style="width: 100%" :columns="columns"
                            :data="floorPlans" :options="tOptions" :theme="theme" class="dataTable">
                <div slot="num_devices" slot-scope="props" style="float: left">
                    {{ props.row.devices.length }}
                </div>
            </v-client-table>

        </div>

    </div>

</template>

<script>
import Vue from 'vue';
import constellation from "@/services/constellation";
import blackhole from '../../services/blackhole.js';
import Panzoom from '@panzoom/panzoom';
import {ClientTable} from 'vue-tables-2';
import floorPlanStore from "@/store/floorPlanStore";

Vue.use(ClientTable);

export default {
    name: "Edit_View",

    created() {
        // retrieve constellation data for form select search
        floorPlanStore.dispatch('getConstellations');
    },

    destroyed() {
        floorPlanStore.dispatch('resetVariables');
    },

    data() {
        return {
            columns: ['name', 'num_devices'],
            columnsNotPlaced: ['name', 'type', 'ipaddress'],
            columnsPlaced: ['name', 'type', 'ipaddress', 'controls'],
            theme: 'bootstrap4',
            tOptions: {
                sortable: ['name', 'num_devices'],
                filterable: ['name', 'num_devices'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    name: 'Name',
                    num_devices: 'Number of Devices'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 10,
                skin: 'table table-striped table-hover',
            },
            tOptions2: {
                sortable: ['name', 'type', 'ipaddress'],
                filterable: ['name', 'type', 'ipaddress'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    name: 'Name',
                    type: 'Type',
                    ipaddress: 'IP Address',
                    controls: 'Controls'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 10,
                skin: 'table table-striped table-hover',
            },
        }
    },

    computed: {
        blackholeImage: {
            get() {
                return floorPlanStore.getters.getBlackholeImage();
            }
        },
        currentConstellation: {
            set(constellation) {
                floorPlanStore.commit('SET_CURRENT_CONSTELLATION', constellation);
            },
            get() {
                return floorPlanStore.getters.getCurrentConstellation();
            }
        },
        constellations: {
            get() {
                return floorPlanStore.getters.getConstellations();
            }
        },
        devices: {
            get() {
                return floorPlanStore.getters.getDevices();
            }
        },
        devicesNotPlaced: {
            set(devices) {
                floorPlanStore.commit('SET_DEVICES_NOT_PLACED', devices);
            },
            get() {
                return floorPlanStore.getters.getDevicesNotPlaced();
            }
        },
        devicesPlaced: {
            set (devices) {
                floorPlanStore.commit('SET_DEVICES_PLACED', devices);
            },
            get() {
                return floorPlanStore.getters.getDevicesPlaced();
            }
        },
        floorPlanDescription: {
            set (description) {
                floorPlanStore.commit('SET_FLOOR_PLAN_DESCRIPTION', description);
            },
            get() {
                return floorPlanStore.getters.getFloorPlanDescription();
            }
        },
        floorPlanId: {
            get() {
                return floorPlanStore.getters.getFloorPlanId();
            }
        },
        floorPlanName: {
            set (name) {
                floorPlanStore.commit('SET_FLOOR_PLAN_NAME', name);
            },
            get() {
                return floorPlanStore.getters.getFloorPlanName();
            }
        },
        floorPlans: {
            get() {
                return floorPlanStore.getters.getFloorPlans();
            }
        },
        image: {
            get() {
                return floorPlanStore.getters.getImage();
            }
        },
        imageNumber: {
            get() {
                return floorPlanStore.getters.getImageNumber();
            }
        },
        uploadedBool: {
            get() {
                return floorPlanStore.getters.getUploadedBool();
            }
        },
    },

    methods: {
        changeCurrentDevice(device) {
            floorPlanStore.dispatch('setCurrentDeviceSelected', device.row);
            floorPlanStore.dispatch('setEditDeviceBool', false);
        },
        configureDeviceIcons() {
            // for all devices, get more information on them with call then
            // create appropriate image to overlay on floor plan image

            for (let i = 0; i < this.devices.length; i++) {
                for (let k = 0; k < this.devicesNotPlaced.length; k++) {
                    if (this.devicesNotPlaced[k].id === this.devices[i].deviceId) {

                        let tempId = "image" + this.imageNumber;

                        // push device to devices placed
                        this.devicesPlaced.push({
                            name: this.devicesNotPlaced[k].name,
                            type: this.devicesNotPlaced[k].type,
                            ipaddress: this.devicesNotPlaced[k].ipaddress,
                            id: this.devices[i].deviceId,
                            xCoord: this.devices[i].coords.xCoord,
                            yCoord: this.devices[i].coords.yCoord,
                            angle: this.devices[i].angle,
                            tempId: tempId
                        });

                        // create div for icon to go in
                        let tempDiv = document.createElement("div");
                        tempDiv.setAttribute("style", "width: 30px; height: 30px; position: absolute;");
                        tempDiv.style.top = this.devices[i].coords.yCoord + 'px';
                        tempDiv.style.left = this.devices[i].coords.xCoord + 'px';
                        tempDiv.id = tempId;
                        floorPlanStore.dispatch('incrementImageNumber');

                        // create image icon to go on floor plan
                        let image = document.createElement("img");
                        image.style.height = floorPlanStore.state.iconSize+'px';
                        image.style.width = floorPlanStore.state.iconSize+'px';
                        image.style.transform = "rotate(" + this.devices[i].angle + "deg)";
                        floorPlanStore.dispatch('setDeviceType', this.devicesNotPlaced[k].type);
                        image.src = floorPlanStore.getters.getDeviceImage();
                        image.style.cursor = "pointer";
                        image.addEventListener("click", function () {
                            this.onDeviceClick(tempId);
                        }.bind(this));

                        // append image to overlay and append that to grid
                        tempDiv.append(image);
                        document.getElementById("panzoom-element").append(tempDiv);

                        // remove device from devices not placed list
                        this.devicesNotPlaced = this.devicesNotPlaced.slice(0, k).concat(this.devicesNotPlaced.slice(k+1, this.devicesNotPlaced.length));
                    }
                }
            }
        },
        editDevicePlacement(device) {
            floorPlanStore.dispatch('setEditDeviceBool', true);
            try {
                document.getElementById(device.tempId+"left").remove();
                document.getElementById(device.tempId+"right").remove();
            } catch (e) {

            }
        },
        getConstellation() {
            this.getFloorPlans();
            this.getDevices();
        },
        getDevices() {
            // remove devices and reset device tables
            this.removeDevices();
            floorPlanStore.dispatch('getDevices');
        },
        getFloorPlans() {
            // get all floor plans from respective constellation
            constellation.readFloorPlans(this.currentConstellation.url).then(response => {
                floorPlanStore.dispatch('setFloorPlans', response.data);
            }).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Floor plans successfully acquired",
                    style: 'success'
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: 'Unsuccessfully acquired floor plans',
                    style: 'error'
                });
            });
        },
        loadFloorPlan(floorPlan) {
            this.floorPlanName = floorPlan.row.name;
            floorPlanStore.dispatch('setFloorPlanId', floorPlan.row._id);
            floorPlanStore.dispatch('setDevices', floorPlan.row.devices);

            // handling for getting photo from blackhole
            floorPlanStore.dispatch('setBlackholeImage', floorPlan.row.image);
            floorPlanStore.dispatch('setImage', blackhole.readImage(floorPlan.row.image));

            // reset everything in case floor plan has been previously loaded
            // remove image elements and rotate icons from document
            if (this.uploadedBool === true) {
                for (let i = 0; i < this.imageNumber; i++) {
                    let tempName = "image" + i;
                    let currImg = document.getElementById(tempName);
                    try {
                        currImg.remove();
                    } catch (e) {

                    }
                }
            }

            // set the height of the floor plan picture window to a fixed size smaller than the 'main' height
            let eee = document.getElementsByClassName("main")[0];
            let pictureWindowHeight = eee.clientHeight - 625;
            floorPlanStore.dispatch('setPictureWindowHeight', pictureWindowHeight);
            pictureWindowHeight = floorPlanStore.getters.getPictureWindowHeight();
            document.getElementById("picture-window").style.height = (pictureWindowHeight) + 'px';

            document.getElementById("picture-window").style.backgroundColor = "black";
            floorPlanStore.dispatch('setUploadedBool', true);

            // get icon size before device icons are configured
            (async () => {
                let img = new Image();
                img.src = this.image;
                await img.decode();
                // img is ready to use
                floorPlanStore.dispatch('setIconSize', {
                    height: img.height,
                    width: img.width
                });
                this.configureDeviceIcons();

                // panzoom stuff
                const elem = document.getElementById('panzoom-element');
                const panzoom = Panzoom(elem, {
                    maxScale: 5
                });
                panzoom.pan(10, 10);
                elem.addEventListener('wheel', panzoom.zoomWithWheel);
            })();

        },
        onDeviceClick(id) {
            // check to see if rotation toggle buttons exist or not
            // toggle rotation buttons
            if (document.getElementById(id+"left") === null) {
                let img = document.getElementById(id);

                // create left rotation button
                let leftRot = document.createElement("div");
                leftRot.setAttribute("style", "position: absolute; height: 30px; width: 30px;");
                leftRot.style.top = parseInt(img.style.top.substring(0, img.style.top.indexOf("p"))) - 30 + 'px';
                leftRot.style.left = parseInt(img.style.left.substring(0, img.style.left.indexOf("p"))) - 7 + 'px';
                leftRot.id = id+"left";
                let leftArrow = document.createElement("i");
                leftArrow.classList.add("fa");
                leftArrow.classList.add("fa-lg");
                leftArrow.classList.add("fa-rotate-left");
                leftArrow.style.color = "#2676b9e8";
                leftRot.addEventListener("click", function() {
                    this.rotateDevice(id, -15);
                }.bind(this));
                leftRot.append(leftArrow);
                document.getElementById("panzoom-element").append(leftRot);

                // create right rotation button
                let rightRot = document.createElement("div");
                rightRot.setAttribute("style", "position: absolute; height: 30px; width: 30px;");
                rightRot.style.top = parseInt(img.style.top.substring(0, img.style.top.indexOf("p"))) - 30 + 'px';
                rightRot.style.left = parseInt(img.style.left.substring(0, img.style.left.indexOf("p"))) + 23 + 'px';
                rightRot.id = id+"right";
                let rightArrow = document.createElement("i");
                rightArrow.classList.add("fa");
                rightArrow.classList.add("fa-lg");
                rightArrow.classList.add("fa-rotate-right");
                rightArrow.style.color = "#2676b9e8";
                rightRot.addEventListener("click", function() {
                    this.rotateDevice(id, 15);
                }.bind(this));
                rightRot.append(rightArrow);
                document.getElementById("panzoom-element").append(rightRot);
            } else {
                document.getElementById(id+"left").remove();
                document.getElementById(id+"right").remove();
            }
        },
        /**
         * Functionality to either place an icon from the devices not placed table or to change the location
         * of a device from the devices placed table
         * @param x The x-coordinate of the cell in the grid overlay clicked on
         * @param y The y-coordinate of the cell in the grid overlay clicked on
         */
        placeIcon(x, y) {
            let currentDevice = floorPlanStore.getters.getCurrentDeviceSelected();
            // placing icon from devices not placed table
            if (currentDevice !== 'None') {
                // create div for icon to go in
                let tempDiv = document.createElement("div");
                let tempId = "image"+this.imageNumber;
                tempDiv.setAttribute("style", "width: 30px; height: 30px; position: absolute;");
                tempDiv.style.top = (y-15)+'px';
                tempDiv.style.left = (x-15)+'px';
                tempDiv.id = tempId;
                floorPlanStore.dispatch('incrementImageNumber');

                // create image icon to go on floor plan
                let image = document.createElement("img");
                image.style.height = floorPlanStore.state.iconSize+'px';
                image.style.width = floorPlanStore.state.iconSize+'px';
                floorPlanStore.dispatch('setDeviceType', currentDevice.type);
                image.src = floorPlanStore.getters.getDeviceImage();
                image.style.cursor = "pointer";
                image.addEventListener("click", function() {
                    this.onDeviceClick(tempId);
                }.bind(this));

                // append image to overlay and append that to grid
                tempDiv.append(image);
                document.getElementById("panzoom-element").append(tempDiv);

                // push device object to devices list
                this.devicesPlaced.push({
                    name: currentDevice.name,
                    type: currentDevice.type,
                    ipaddress: currentDevice.ipaddress,
                    id: currentDevice.id,
                    xCoord: x-15,
                    yCoord: y-15,
                    angle: 0,
                    tempId: tempId
                });
                // remove device from devices not placed list
                for (let i = 0; i < this.devicesNotPlaced.length; i++) {
                    if (this.devicesNotPlaced[i].id === currentDevice.id) {
                        this.devicesNotPlaced = this.devicesNotPlaced.slice(0, i).concat(this.devicesNotPlaced.slice(i+1, this.devicesNotPlaced.length));
                    }
                }
            }

            // editing location of icon from devices placed table
            if (floorPlanStore.state.editDeviceBool === true) {
                let imageEdit = document.getElementById(currentDevice.id);
                imageEdit.style.top = (y-15)+'px';
                imageEdit.style.left = (x-15)+'px';

                // update coordinates of device
                for (let i = 0; i < this.devicesPlaced.length; i++) {
                    if (this.devicesPlaced[i].tempId === currentDevice.id) {
                        this.devicesPlaced[i].xCoord = x-15;
                        this.devicesPlaced[i].yCoord = y-15;
                    }
                }
                floorPlanStore.dispatch('setEditDeviceBool', false);
            }
            floorPlanStore.dispatch('setCurrentDeviceSelected', 'None');
        },
        removeDevice(device) {
            let imageRemove = document.getElementById(device.tempId);
            imageRemove.remove();
            this.devicesNotPlaced.push({
                name: device.name,
                type: device.type,
                ipaddress: device.ipaddress
            });
            for (let i = 0; i < this.devicesPlaced.length; i++) {
                if (this.devicesPlaced[i].tempId === device.tempId) {
                    this.devicesPlaced = this.devicesPlaced.slice(0, i).concat(this.devicesPlaced.slice(i+1, this.devicesPlaced.length));
                }
            }
            try {
                document.getElementById(device.tempId+"left").remove();
                document.getElementById(device.tempId+"right").remove();
            } catch (e) {

            }
        },
        removeDevices() {
            // remove image elements and rotate icons from document
            for (let i = 0; i < this.imageNumber; i++) {
                let tempName = "image"+i;
                let currImg = document.getElementById(tempName);
                try {
                    currImg.remove();
                } catch (e) {

                }
                let leftRot = document.getElementById(tempName + "left");
                try {
                    leftRot.remove();
                } catch(e) {

                }
                let rightRot = document.getElementById(tempName + "right");
                try {
                    rightRot.remove();
                } catch(e) {

                }
            }

            // move over devices from placed to not placed table
            for (let i = 0; i < this.devicesPlaced.length; i++) {
                this.devicesNotPlaced.push({
                    name: this.devicesPlaced[i].name,
                    type: this.devicesPlaced[i].type,
                    ipaddress: this.devicesPlaced[i].ipaddress
                });
            }
            this.devicesPlaced = [];
        },
        rotateDevice(id, degree) {
            // rotate device icon
            let img = document.getElementById(id);
            let openPar = img.style.transform.indexOf("(");
            let closedPar = img.style.transform.indexOf(")");
            let currRotation = img.style.transform.substring(openPar + 1, closedPar - 3);
            if (currRotation === "") {
                img.style.transform = "rotate(" + degree + "deg)";
            } else {
                img.style.transform = "rotate(" + (parseInt(currRotation) + degree) + "deg)";
            }

            // update the device's angle
            for (let i = 0; i < this.devicesPlaced.length; i++) {
                if (this.devicesPlaced[i].tempId === id) {
                    this.devicesPlaced[i].angle += degree;
                }
            }
        },
        submitFloorPlan() {
            floorPlanStore.dispatch('setDevicesToBeSubmitted');
            let data = {
                image: this.blackholeImage,
                description: this.floorPlanDescription,
                devices: floorPlanStore.getters.getDevicesToBeSubmitted(),
                name: this.floorPlanName,
                id: this.floorPlanId
            }
            constellation.updateFloorPlan(this.currentConstellation.url, data).then(response => {
                this.$mToast({
                    title: 'Success',
                    text: "Successfully updated floor plan",
                    style: 'success'
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Error updating floor plan",
                    style: 'error'
                });
            });
        }
    }
}
</script>